import React, { useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import TableCustom from "src/components/Table/CustomTable"
import { BackGround } from "../styled"
import Button from "src/components/MyButton/Button"
import ModalAddThanhVien from "../Modal/ModalAddThanhVien"
import CB1 from "src/components/Modal/CB1"
import Notice from "src/components/Notice"
import { Tooltip } from "antd"
import moment from "moment"

function Curriculumvitae3({
  form,
  userInfo,
  listRelationship,
  setListRelationship,
  btnShow,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const showModal = () => {
    setIsModalOpen(true)
  }

  const columns = [
    {
      title: "STT",
      key: "stt",
      align: "center",
      render: (value, record, index) => index + 1,
      width: "5%",
    },
    {
      title: "Họ Tên",
      dataIndex: "Name",
      key: "Name",
      width: "10%",
    },
    {
      title: "Năm Sinh",
      dataIndex: "BirthYear",
      key: "BirthYear",
      align: "center",
      width: 100,
      render: value => (!!value ? moment(value, "YYYY").format("YYYY") : ""),
    },
    {
      title: "Quan Hệ",
      dataIndex: "Relationship",
      key: "Relationship",
      width: "auto",
      width: "10%",
    },
    // {
    //   title: "Thứ tự",
    //   dataIndex: "SortOrder",
    //   key: "SortOrder",
    //   align: "center",
    // },
    {
      title: "Điện thoại",
      dataIndex: "PhoneNumber",
      key: "PhoneNumber",
      align: "center",
      width: "auto",
      width: "10%",
    },
    {
      title: "Nghề nghiệp",
      dataIndex: "Career",
      align: "center",
      key: "Career",
      width: "auto",
      width: "10%",
    },
    {
      title: "Cơ quan công tác",
      dataIndex: "Company",
      align: "center",
      key: "Company",
      width: "auto",
    },
    {
      title: "Địa chỉ",
      dataIndex: "Address",
      key: "Address",
      align: "center",
      width: "auto",
      width: "10%",

      render: (value, record, idx) => (
        <div>
          <Tooltip title={value}>
            <div className="max-line1">{value}</div>
          </Tooltip>
          {btnShow?.IsUpdate && (
            <FloatActionWrapper size="small" className="float-action__wrapper">
              <div className="d-flex">
                <ButtonCircle
                  title="Chỉnh sửa"
                  iconName="edit-green"
                  style={{
                    background: "#EDF6FC",
                    boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                  }}
                  onClick={() => {
                    setIsModalOpen({ ...record, index: idx, isEdit: true })
                  }}
                />
                <ButtonCircle
                  title="Xóa"
                  iconName="deleteRow"
                  style={{
                    background: "#EDF6FC",
                    boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                  }}
                  onClick={() => {
                    CB1({
                      title: `Bạn có chắc chắn muốn xoá "${record.Name}" không?`,
                      icon: "warning-usb",
                      okText: "Đồng ý",
                      onOk: () => {
                        setListRelationship(pre => {
                          const body = [...pre]
                          const a1 = body.slice(0, idx)
                          const a2 = body.slice(idx + 1, body?.length)
                          return [...a1, ...a2]
                        })
                        Notice({
                          isSuccess: true,
                          msg: "Cập nhật thành công!",
                        })
                      },
                    })
                  }}
                />
              </div>
            </FloatActionWrapper>
          )}
        </div>
      ),
    },
  ]

  return (
    <BackGround>
      <div className="d-flex-sb mb-8">
        <div className="fw-600 fs-24">Mối quan hệ</div>
        {btnShow?.IsUpdate && (
          <Button
            style={{ float: "right" }}
            btnType="primary"
            onClick={showModal}
          >
            Thêm thành viên
          </Button>
        )}
      </div>
      <TableCustom
        columns={columns}
        dataSource={listRelationship}
        pagination={false}
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: "700px" }}
      />
      {!!isModalOpen && (
        <ModalAddThanhVien
          isModalOpen={isModalOpen}
          handleCancel={() => {
            setIsModalOpen(false)
          }}
          setListRelationship={setListRelationship}
        />
      )}
    </BackGround>
  )
}

export default Curriculumvitae3
