import QueryString from "qs"
import {
  apiGetAllCourseByG,
  apiGetAllListPost,
  apiGetAllOrBySearchGuest,
  apiGetAllPackageGuest,
  apiGetAllSeminarG,
  apiGetAllTopicGuest,
  apiGetCategoryPost,
  apiGetDetailByG,
  apiGetDetailCourseForGuest,
  apiGetDetailSeminar,
  apiGetListPostByCategoryPostID,
  apiGetListPostByTags,
  apiGetListPostRelate,
  apiGetListStaticNavbar,
  apiGetPackageDetailsByIDForGuest,
  apiGetPostHome,
  apiGetRegionId,
  apiGetReviewByPackageIDForGuest,
  apiGuestSendContact,
  apiInsertDiplomaG,
  apiSeminarRegister,
  apiGuestGetAllProcurementBidding,
  apiGuestGetDetailsProcurementBidding,
  apiGetTopicByTypeGuest,
  apiGetListDocument,
  apiGuestInsertBiddingCompany,
  apiGetListTopicG,
  apiGetAllCourseForGuest,
  apiRegisterRecruit,
  apiAllPostCommnetByType,
  apiSeminarUserInsertGuest,
  apiGetAllRecruitForUser,
  apiLookUpExamResult,
  apiGetAllRecruitStandardForUser,
  apiGetDetailRecruitStandardForUser,
  apiGetDetailPackage,
  apiGuestGetListCate,
  apiGuestGetAllTopicPackage,
  apiGetAllGuestPackageCommnetByType,
} from "./apiRouter"
import http from "./index"
const getPackageReview = body =>
  http.post(apiGetReviewByPackageIDForGuest, body)
const getPackageDetail = params =>
  http.get(apiGetPackageDetailsByIDForGuest, { params })
const guestGetAllTopicPackage = () => http.get(apiGuestGetAllTopicPackage)

const getDetail = body => {
  const params = QueryString.stringify(body)
  return http.get(`${apiGetDetailByG}?${params}`)
}
const getDetailCourseForGuest = body => {
  return http.post(apiGetDetailCourseForGuest, body)
}

const getDetailSeminar = body => {
  const params = QueryString.stringify(body)
  return http.patch(`${apiGetDetailSeminar}?${params}`)
}

const getListPost = body => http.post(apiGetListPostByCategoryPostID, body)
const getPackage = body => http.post(apiGetAllPackageGuest, body)
const insertDiplomaG = body => http.post(apiInsertDiplomaG, body)
const getAllTopic = () => http.post(apiGetAllTopicGuest)

const getListPostByTags = body => http.post(apiGetListPostByTags, body)
const getAllListPost = body => http.post(apiGetAllListPost, body)
const getStaticNav = () => http.get(apiGetListStaticNavbar)
const sendContact = body => http.post(apiGuestSendContact, body)
const getAllCourseByG = body => http.post(apiGetAllCourseByG, body)
const getAllCourseForGuest = body => http.post(apiGetAllCourseForGuest, body)
const getAllSeminarG = body => http.post(apiGetAllSeminarG, body)
const registerSeminar = body => http.post(apiSeminarRegister, body)
const SeminarUserInsertGuest = body =>
  http.post(apiSeminarUserInsertGuest, body)
const getPostHome = () => http.get(apiGetPostHome)
const getRegionById = params => http.get(apiGetRegionId, { params })
const getCategoryPost = () => http.get(apiGetCategoryPost)
const getListPostRelate = params => http.get(apiGetListPostRelate, { params })
const getTopicByType = () => http.get(apiGetTopicByTypeGuest)
const getListTopicG = () => http.get(apiGetListTopicG)

const getAllOrBySearch = body => http.post(apiGetAllOrBySearchGuest, body)

const getAllProcurementBidding = body =>
  http.post(apiGuestGetAllProcurementBidding, body)

const getDetailsProcurementBidding = body => {
  const params = QueryString.stringify(body)
  return http.patch(`${apiGuestGetDetailsProcurementBidding}?${params}`)
}

const getComment = body => {
  const params = QueryString.stringify(body)
  return http.get(`${apiAllPostCommnetByType}?${params}`)
}

const getListDocument = () => http.get(apiGetListDocument)
const insertBiddingCompany = body =>
  http.post(apiGuestInsertBiddingCompany, body)
const registerRecruit = body => http.post(apiRegisterRecruit, body)
const getAllRecruitForUser = body => http.post(apiGetAllRecruitForUser, body)
const lookUpExamResult = params => http.get(apiLookUpExamResult, { params })
const getAllRecruitStandardForUser = body =>
  http.post(apiGetAllRecruitStandardForUser, body)
const getDetailRecruitStandardForUser = body => {
  const params = QueryString.stringify(body)
  return http.patch(`${apiGetDetailRecruitStandardForUser}?${params}`)
}
const getDetailPackage = body => {
  return http.post(apiGetDetailPackage, body)
}

const guestGetListCate = () => http.get(apiGuestGetListCate)
const getAllPackageCommnetByType = body => {
  const params = QueryString.stringify(body)
  return http.get(`${apiGetAllGuestPackageCommnetByType}?${params}`)
}

const GuestServices = {
  getPackageReview,
  getPackageDetail,
  getListPost,
  getPackage,
  getStaticNav,
  getDetail,
  sendContact,
  getPostHome,
  getRegionById,
  getCategoryPost,
  getListPostRelate,
  getListPostByTags,
  getAllListPost,
  getAllTopic,
  getAllCourseByG,
  getDetailCourseForGuest,
  insertDiplomaG,
  getAllSeminarG,
  getDetailSeminar,
  registerSeminar,
  getListTopicG,
  getTopicByType,
  getAllOrBySearch,
  getAllProcurementBidding,
  getDetailsProcurementBidding,
  getListDocument,
  insertBiddingCompany,
  getAllCourseForGuest,
  registerRecruit,
  getComment,
  SeminarUserInsertGuest,
  getAllRecruitForUser,
  lookUpExamResult,
  getAllRecruitStandardForUser,
  getDetailRecruitStandardForUser,
  getDetailPackage,
  guestGetListCate,
  guestGetAllTopicPackage,
  getAllPackageCommnetByType,
}
export default GuestServices
