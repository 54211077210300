import { Col, DatePicker, Form, Input, Row, Select } from "antd"
import { useState } from "react"
import { useSelector } from "react-redux"
import SelectAddress from "src/components/SelectAddress"
import { SYSTEM_KEY } from "src/constants/constants"
import { getListComboByKey } from "src/lib/utils"
import { BackGround } from "../styled"

export default function Curriculumvitae2(props) {
  const { form, userInfo, listProvince } = props
  const { Option } = Select
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const formStyle = {
    maxWidth: "100%",
    width: "100%",
  }
  const [loading, setLoading] = useState(false)
  return (
    <BackGround>
      {/* <Row gutter={[16, 16]}> */}
      {/* <Col span={24}>
          <div className="fw-600 fs-24">Thông tin cá nhân:</div>
        </Col> */}
      {/* <Col span={24}>
          <Form.Item name="PlaceOfBirth" label="Nơi sinh" x>
            <Input placeholder="Nhập" />
          </Form.Item>
        </Col> */}
      {/* <Col span={24}>
          <h3 className="mt-12 fs-16">
            <b>Địa chỉ nơi cấp giấy khai sinh</b>
          </h3>
        </Col> */}
      {/* </Row> */}
      {/* NQ: nguyên quán, GKS: giấy khai sinh */}
      {/* <Row gutter={[16, 16]}>
        <Col span={24}>
          <SelectAddress
            required={true}
            floating={false}
            form={form}
            listFormName={["ProvinceIDGKS", "DistrictIDGKS", "WardIDGKS"]}
            initValue={{
              DistrictIDGKS: !!userInfo?.DistrictIDGKS
                ? userInfo?.DistrictIDGKS
                : undefined,
              ProvinceIDGKS: !!userInfo?.ProvinceIDGKS
                ? userInfo?.ProvinceIDGKS
                : undefined,
              WardIDGKS: !!userInfo?.WardIDGKS
                ? userInfo?.WardIDGKS
                : undefined,
            }}
          />
        </Col>
      </Row> */}
      {/* <Row gutter={[16, 16]}>
        <Col span={24}>
          <h3 className="mt-12">
            <b>Nguyên quán</b>
          </h3>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <SelectAddress
            required={true}
            floating={false}
            form={form}
            listFormName={["ProvinceIDNQ", "DistrictIDNQ", "WardIDNQ"]}
            initValue={{
              DistrictIDNQ: !!userInfo?.DistrictIDNQ
                ? userInfo?.DistrictIDNQ
                : undefined,
              ProvinceIDNQ: !!userInfo?.ProvinceIDNQ
                ? userInfo?.ProvinceIDNQ
                : undefined,
              WardIDNQ: !!userInfo?.WardIDNQ ? userInfo?.WardIDNQ : undefined,
            }}
          />
        </Col>
      </Row> */}
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            name="NgayKetNapDang"
            label="Kết nạp Đảng cộng sản Việt Nam ngày"
          >
            <DatePicker format="DD/MM/YYYY" placeholder="Nhập" allowClear />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name="NoiKetNapDang" label="Nơi kết nạp">
            <Input placeholder="Nhập" />
          </Form.Item>
        </Col>
        {/* <Col span={8}>
          <Form.Item
            name="NgayKetNapDoanTNCSHCM"
            label="Kết nạp Đoàn thanh niên Cộng sản Hồ Chí Minh ngày"
          >
            <DatePicker format="DD/MM/YYYY" placeholder="Nhập" allowClear />
          </Form.Item>
        </Col> */}
      </Row>
    </BackGround>
  )
}
