import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Spin,
} from "antd"
import { useEffect, useMemo, useState } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import { RenderTiny } from "src/components/TinyEditor"
function ModalAddStudtyAfter(props) {
  const { show, cancel, setProcessOperation } = props
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        ...show,
      })
    }
  }, [])

  const handleSubmit = async () => {
    const value = await form.validateFields()
    if (!!value) {
      if (!!show?.isEdit) {
        setProcessOperation(pre => {
          pre[show?.index] = {
            ...value,
            // TimeFrom: value?.timework?.length
            //   ? value?.timework?.[0]?.format()
            //   : undefined,
            // TimeTo: value?.timework?.length
            //   ? value?.timework?.[1]?.format()
            //   : undefined,
            // timework: undefined,
          }
          return [...pre]
        })
      } else {
        setProcessOperation(pre => [
          ...pre,
          {
            ...value,
            // TimeFrom: value?.timework?.length
            //   ? value?.timework?.[0]?.format()
            //   : undefined,
            // TimeTo: value?.timework?.length
            //   ? value?.timework?.[1]?.format()
            //   : undefined,
            // timework: undefined,
          },
        ])
      }
    }
    cancel()
    Notice({
      isSuccess: true,
      msg: "Cập nhật thành công!",
    })
  }
  return (
    <CustomModal
      title={show?.isEdit ? "Sửa Thông Tin" : "Thêm thông tin"}
      open={!!show}
      onCancel={cancel}
      width={"50%"}
      footer={
        <Button
          style={{ float: "right" }}
          btnType="primary"
          htmlType="submit"
          onClick={() => handleSubmit()}
        >
          Ghi
        </Button>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Tên công ty"
                name="Unit"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được bỏ trống!",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
            <Form.Item
              label="Đơn vị/Phòng ban"
              name="Unit"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được bỏ trống!",
                },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col> */}
            <Col span={10}>
              <Form.Item label="Chức vụ" name="Position">
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
            <Form.Item label="Chức danh" name="Title">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Loại QTCT" name="LoaiQTCT">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col> */}
            <Col span={10}>
              <Form.Item label="Thời gian làm" name="Time">
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
            <Form.Item
              label="Thông tin của hiện tại?"
              name="Presnent"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được bỏ trống!",
                },
              ]}
            >
              <Radio.Group className="radio-learning">
                <Radio value={true}>Đúng</Radio>
                <Radio value={false}>Không</Radio>
              </Radio.Group>
            </Form.Item>
          </Col> */}


            <Col span={4}>
              <Form.Item label="Thứ tự" name="SortOrder">
                <InputNumber
                  placeholder="nhập"
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Mô tả"
                style={{ overflow: 'auto' }}
                name="Description">
                {useMemo(
                  () => (
                    <RenderTiny setLoading={setLoading} />
                  ),
                  [],
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>

    </CustomModal>
  )
}

export default ModalAddStudtyAfter
