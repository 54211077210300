import { Col, Form, Input, InputNumber, Row } from "antd"
import moment from "moment"
import { useEffect } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
function ModalAddStudty(props) {
  const { show, cancel, setProcessStudys } = props
  const [form] = Form.useForm()
  useEffect(() => {
    if (show) {
      form.setFieldsValue({
        ...show,
        namsinh: moment(show.namsinh),
      })
    } else {
      form.resetFields()
    }
  }, [show])

  const handleSubmit = async () => {
    if (!!show?.isEdit) {
      const value = await form.validateFields()
      setProcessStudys(pre => {
        pre[show?.index] = {
          ...value,
          // TimeFrom: value?.time?.length
          //   ? value?.time?.[0]?.format()
          //   : undefined,
          // TimeTo: value?.time?.length ? value?.time?.[1]?.format() : undefined,
          // time: undefined,
        }
        return [...pre]
      })
    } else {
      const value = await form.validateFields()
      setProcessStudys(pre => [
        ...pre,
        {
          ...value,
          // TimeFrom: value?.time?.length
          //   ? value?.time?.[0]?.format()
          //   : undefined,
          // TimeTo: value?.time?.length ? value?.time?.[1]?.format() : undefined,
          // time: undefined,
        },
      ])
    }
    cancel()
    Notice({
      isSuccess: true,
      msg: "Cập nhật thành công!",
    })
  }
  return (
    <CustomModal
      title={!!show?.isEdit ? "Sửa Thông Tin" : "Thêm thông tin"}
      open={!!show}
      onCancel={cancel}
      width="60%"
      footer={
        <Button
          style={{ float: "right" }}
          btnType="primary"
          htmlType="submit"
          onClick={() => handleSubmit()}
        >
          Ghi
        </Button>
      }
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Thời gian học"
              name="Time"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
            >
              <Input placeholder="Nhập" />
              {/* <DatePicker.RangePicker
                  format={"DD/MM/YYYY"}
                  placeholder={["Từ ngày", "Đến ngày"]}
                /> */}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Cơ sở đào tạo"
              name="StudyAt"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ngành học"
              name="Major"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được để trống!",
                },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Hình thức đào tạo" name="FormTraining">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Thứ tự" name="SortOrder">
              <InputNumber
                placeholder="nhập"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Địa chỉ" name="StudyAddress">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Văn bằng chứng chỉ" name="Certificate">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default ModalAddStudty
