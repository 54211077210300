import { CheckCircleFilled } from "@ant-design/icons"
import moment from "moment"
import { useState } from "react"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import CB1 from "src/components/Modal/CB1"
import Button from "src/components/MyButton/Button"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import Notice from "src/components/Notice"
import TableCustom from "src/components/Table/CustomTable"
import ModalAddStudty from "../Modal/ModalAddStudty"
import ModalAddStudtyAfter from "../Modal/ModalAddStudtyAfter"
import { BackGround, Title } from "../styled"
import { Tooltip } from "antd"

function Curriculumvitae4({
  processStudys,
  setProcessStudys,
  processOperation,
  setProcessOperation,
  btnShow,
}) {
  const [openModalAddStudty, setOpenModalAddStudty] = useState(false)
  const [openModalAddStudtyAfter, setOpenModalAddStudtyAfter] = useState(false)

  const columnsHT = [
    {
      title: "STT",
      key: "key",
      render: (value, record, index) => index + 1,
      align: "center",
      width: 60,
    },
    {
      title: "Thời gian học",
      dataIndex: "Time",
      key: "Time",
      width: 150,
      align: "center",

      // render: (value, record) =>
      //   !!record.TimeFrom ? (
      //     <div>
      //       {moment(record.TimeFrom).format("DD/MM/YYYY")} -{" "}
      //       {moment(record.TimeTo).format("DD/MM/YYYY")}
      //     </div>
      //   ) : (
      //     <></>
      //   ),
    },
    {
      title: "Cơ sở đào tạo",
      dataIndex: "StudyAt",
      key: "StudyAt",
      align: "center",
      width: "15%",
      render: (value, record, idx) => (
        <Tooltip title={value}>
          <div className="max-line1">{value}</div>
        </Tooltip>
      ),
    },
    {
      title: "Ngành học",
      dataIndex: "Major",
      key: "Major",
      align: "center",
      width: "15%",
    },
    {
      title: "Hình thức đào tạo",
      dataIndex: "FormTraining",
      key: "FormTraining",
      width: 150,
      align: "center",
    },
    {
      title: "Địa chỉ",
      dataIndex: "StudyAddress",
      key: "StudyAddress",
      align: "center",
      width: "15%",
      render: (value, record, idx) => (
        <Tooltip title={value}>
          <div className="max-line1">{value}</div>
        </Tooltip>
      ),
    },
    {
      title: "Văn bằng chứng chỉ",
      dataIndex: "Certificate",
      key: "Certificate",
      render: (value, record, idx) => (
        <div>
          <Tooltip title={value}>
            <div className="max-line1">{value}</div>
          </Tooltip>
          {btnShow?.IsUpdate && (
            <FloatActionWrapper size="small" className="float-action__wrapper">
              <div className="d-flex">
                <ButtonCircle
                  title="Chỉnh sửa"
                  iconName="edit-green"
                  style={{
                    background: "#EDF6FC",
                    boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                  }}
                  onClick={() => {
                    setOpenModalAddStudty({
                      ...record,
                      index: idx,
                      isEdit: true,
                    })
                  }}
                />
                <ButtonCircle
                  title="Xóa"
                  iconName="deleteRow"
                  style={{
                    background: "#EDF6FC",
                    boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                  }}
                  onClick={() => {
                    CB1({
                      title: `Bạn có chắc chắn muốn xoá không?`,
                      icon: "warning-usb",
                      okText: "Đồng ý",
                      onOk: () => {
                        setProcessStudys(pre => {
                          if (!!pre?.length) {
                            const body = [...pre]
                            const a1 = body.slice(0, idx)
                            const a2 = body.slice(idx + 1, body?.length)
                            return [...a1, ...a2]
                          } else {
                            return []
                          }
                        })
                        Notice({
                          isSuccess: true,
                          msg: "Cập nhật thành công!",
                        })
                      },
                    })
                  }}
                />
              </div>
            </FloatActionWrapper>
          )}
        </div>
      ),
    },
  ]
  const columnsHD = [
    {
      title: "STT",
      key: "key",
      render: (value, record, index) => index + 1,
      align: "center",
      width: 60,
    },
    {
      title: "Thời gian làm",
      dataIndex: "Time",
      key: "Time",
      width: 200,
      align: "center",
      // render: (value, record) =>
      //   !!record.TimeFrom ? (
      //     <div>
      //       {moment(record.TimeFrom).format("DD/MM/YYYY")} -{" "}
      //       {moment(record.TimeTo).format("DD/MM/YYYY")}
      //     </div>
      //   ) : (
      //     <></>
      //   ),
    },
    {
      title: "Tên công ty",
      dataIndex: "Unit",
      key: "Unit",
      align: "center",
      width: "20%",
    },
    {
      title: "Chức vụ",
      dataIndex: "Position",
      key: "Position",
      align: "center",
      width: 200,
    },
    // {
    //   title: "Mô",
    //   dataIndex: "Title",
    //   key: "Title",
    // },
    // {
    //   title: "Thứ tự",
    //   dataIndex: "SortOrder",
    //   key: "SortOrder",
    //   align: "center",
    // },
    // {
    //   title: "Hiện tại",
    //   dataIndex: "Presnent",
    //   key: "Presnent",
    //   align: "center",
    //   render: (value, record) =>
    //     !!value ? (
    //       <CheckCircleFilled style={{ color: "green", fontSize: "25px" }} />
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "Mô tả",
      dataIndex: "Description",
      key: "Description",

      render: (value, record, idx) => (
        <div>
          <Tooltip
            title={
              <div
                style={{
                  maxHeight: "40vh",
                  overflow: "hidden auto",
                }}
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              />
            }
          >
            <div
              className="max-line3"
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            />
          </Tooltip>
          {btnShow?.IsUpdate && (
            <FloatActionWrapper size="small" className="float-action__wrapper">
              <div className="d-flex">
                <ButtonCircle
                  title="Chỉnh sửa"
                  iconName="edit-green"
                  style={{
                    background: "#EDF6FC",
                    boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                  }}
                  onClick={() => {
                    setOpenModalAddStudtyAfter({
                      ...record,
                      index: idx,
                      isEdit: true,
                    })
                  }}
                />
                <ButtonCircle
                  title="Xóa"
                  iconName="deleteRow"
                  style={{
                    background: "#EDF6FC",
                    boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                  }}
                  onClick={() => {
                    CB1({
                      title: `Bạn có chắc chắn muốn xoá không?`,
                      icon: "warning-usb",
                      okText: "Đồng ý",
                      onOk: () => {
                        setProcessOperation(pre => {
                          if (!!pre?.length) {
                            const body = [...pre]
                            const a1 = body.slice(0, idx)
                            const a2 = body.slice(idx + 1, body?.length)
                            return [...a1, ...a2]
                          } else {
                            return []
                          }
                        })
                        Notice({
                          isSuccess: true,
                          msg: "Cập nhật thành công!",
                        })
                      },
                    })
                  }}
                />
              </div>
            </FloatActionWrapper>
          )}
        </div>
      ),
    },
  ]
  return (
    <BackGround>
      <div className="d-flex-sb" id="part-4">
        <div>
          <Title>QUÁ TRÌNH HỌC TẬP CỦA BẢN THÂN</Title>
          <p>
            (Yêu cầu khai chi tiết 2 giai đoạn học Phổ thông và học sau phổ
            thông (nếu có))
          </p>
        </div>
        {btnShow?.IsUpdate && (
          <Button
            btnType="primary"
            style={{ float: "right" }}
            onClick={() => setOpenModalAddStudty(true)}
          >
            Thêm mới
          </Button>
        )}
      </div>
      <TableCustom
        columns={columnsHT}
        dataSource={processStudys}
        pagination={false}
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: "700px" }}
      />
      <div className="d-flex-sb" id="part-6">
        <div>
          <Title>QUÁ TRÌNH LÀM VIỆC CỦA BẢN THÂN</Title>
          <div>
            (Yêu cầu khai đầy đủ thông tin làm việc trước khi vào công ty)
          </div>
        </div>
        <div>
          {btnShow?.IsUpdate && (
            <Button
              btnType="primary"
              style={{ float: "right" }}
              onClick={() => setOpenModalAddStudtyAfter(true)}
            >
              Thêm mới
            </Button>
          )}
        </div>
      </div>
      <TableCustom
        columns={columnsHD}
        dataSource={processOperation}
        pagination={false}
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: "700px" }}
      />

      {/* <div className="d-flex-sb" id="part-6">
        <Title>QUÁ TRÌNH LÀM VIỆC CỦA BẢN THÂN</Title>
        <Button btnType="primary" style={{ float: "right" }}>
          Thêm mới danh sách con
        </Button>
      </div>
      <TableCustom
        columns={columns}
        dataSource={data}
        pagination={false}
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: "700px" }}
      /> */}
      {!!openModalAddStudtyAfter && (
        <ModalAddStudtyAfter
          show={openModalAddStudtyAfter}
          cancel={() => {
            setOpenModalAddStudtyAfter(false)
          }}
          processOperation={processOperation}
          setProcessOperation={setProcessOperation}
        />
      )}
      {!!openModalAddStudty && (
        <ModalAddStudty
          show={openModalAddStudty}
          cancel={() => {
            setOpenModalAddStudty(false)
          }}
          processStudys={processStudys}
          setProcessStudys={setProcessStudys}
        />
      )}
      {/* <ModalAddChild
        show={showModalChild}
        cancel={() => {
          setShowModalChild(false)
        }}
        dataChild={dataChild}
      /> */}
    </BackGround>
  )
}

export default Curriculumvitae4
