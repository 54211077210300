import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  activeTimeTestPoint: {},
}

export const activeTimeTestPointSlice = createSlice({
  name: "activeTimeTestPoint",
  initialState,
  reducers: {
    setTime: (state, action) => {
      state.activeTimeTestPoint = action.payload
    },
  },
})

export const { setTime } = activeTimeTestPointSlice.actions

export default activeTimeTestPointSlice.reducer
