import { LeftOutlined } from "@ant-design/icons"
import { Affix, Anchor, Form, Spin } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import STORAGE, { getStorage } from "src/lib/storage"
import RegionService from "src/services/RegionService"
import UserService from "src/services/UserService"
import Curriculumvitae from "./components/Curriculumvitae"
import Curriculumvitae2 from "./components/Curriculumvitae2"
import Curriculumvitae3 from "./components/Curriculumvitae3"
import Curriculumvitae4 from "./components/Curriculumvitae4"
import Curriculumvitae5 from "./components/Curriculumvitae5"
import { PageIndexStyle } from "./styled"
import FileService from "src/services/FileService"
function ProfilePage({ isDraw = false, heightForm = false, UserMyId }) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [listProvince, setListProvince] = useState()
  const [listRelationship, setListRelationship] = useState([])
  const [processStudys, setProcessStudys] = useState([])
  const [processOperation, setProcessOperation] = useState([])
  const [documentRequiment, setDocumentRequiment] = useState([])
  const user = getStorage(STORAGE.USER_INFO)
  const [userInfo, setUserInfo] = useState({})
  const [btnShow, setBtnShow] = useState({})
  const [avatarUpload, setAvatarUpload] = useState("")
  const [listFormData, setListFormData] = useState(new FormData())
  const UserID = isDraw?.UserID
    ? isDraw?.UserID
    : UserMyId
    ? UserMyId
    : user?.UserID

  const { Link } = Anchor
  const getListEthnicity = () => {
    setLoading(true)
    RegionService.getByRegionId({ regionId: 234 })
      .then(res => {
        if (res?.isError) return
        setListProvince(res?.Object)
      })
      .finally(setLoading(false))
  }
  const getListProvinceVN = () => {
    setLoading(true)
    RegionService.getByRegionId({ regionId: 234 })
      .then(res => {
        if (res?.isError) return
        setListProvince(res?.Object)
      })
      .finally(setLoading(false))
  }
  const getProfileUser = () => {
    setLoading(true)
    UserService.getProfileUser({
      userID: UserMyId,
    })
      .then(res => {
        if (res.isOk) {
          setUserInfo(res.Object?.Data)
          setBtnShow(res.Object?.ButtonShow)
          form.setFieldsValue({
            ...res.Object.Data,
            ProvinceID: undefined,
            DistrictID: undefined,
            WardID: undefined,
            ProvinceIDNQ: undefined,
            DistrictIDNQ: undefined,
            WardIDNQ: undefined,
            ProvinceIDGKS: undefined,
            DistrictIDGKS: undefined,
            WardIDGKS: undefined,
            Sex: res.Object.Data.Sex,
            Birthday: res.Object.Data.Birthday
              ? moment(res.Object.Data.Birthday)
              : null,
            IdentificationDateRange: res.Object.Data.IdentificationDateRange
              ? moment(res.Object.Data.IdentificationDateRange)
              : null,
            NgayKetNapDang: res.Object.Data.NgayKetNapDang
              ? moment(res.Object.Data.NgayKetNapDang)
              : null,
            NgayKetNapDoanTNCSHCM: res.Object.Data.NgayKetNapDoanTNCSHCM
              ? moment(res.Object.Data.NgayKetNapDoanTNCSHCM)
              : null,
            NgoaiNgu: res.Object.Data.NgoaiNgu ? res.Object.Data.NgoaiNgu : 0,
            EthnicityID: res.Object.Data.EthnicityID
              ? res.Object.Data.EthnicityID
              : 0,
            TrinhDoHocVan: res.Object?.Data?.TrinhDoHocVan
              ? res.Object.Data.TrinhDoHocVan
              : 0,
            ReligionID: res.Object?.Data?.ReligionID
              ? res.Object.Data.ReligionID
              : 0,
          })
          setListRelationship(res.Object?.Data?.Relatives)
          setProcessStudys(res.Object?.Data?.ProcessStudys)
          setProcessOperation(res.Object?.Data?.ProcessOperation)
          setDocumentRequiment(res.Object?.Data?.documentRequiment)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleUpload = async () => {
    listFormData.append("UserID", UserID)
    FileService.uploadFileListUser(listFormData)
      .then(res => {
        if (res.isError) return
        getProfileUser()
      })
      .finally(() => setLoading(false))
  }

  const handleSubmit = async () => {
    const values = await form.validateFields()
    try {
      setLoading(true)
      let resAvatar
      if (avatarUpload) {
        const formData = new FormData()
        formData.append("file", avatarUpload)
        resAvatar = await FileService.uploadFile(formData)
        if (resAvatar.isError) return
      }
      const body = {
        userID: UserID,
        ...values,
        Birthday: values?.Birthday?.format(),
        IdentificationDateRange: values?.IdentificationDateRange?.format(),
        NgayKetNapDang: values?.NgayKetNapDang?.format(),
        NgayKetNapDoanTNCSHCM: values?.NgayKetNapDoanTNCSHCM?.format(),
        Avatar: resAvatar?.Object || userInfo?.Avatar,
        Relatives: listRelationship,
        ProcessStudys: processStudys,
        ProcessOperation: processOperation,
      }
      const res = await UserService.updateUserProfile(body)
      if (res?.isError) return
      Notice({
        isSuccess: true,
        msg: "Cập nhật thành công!",
      })
    } catch {
      setLoading(false)
      return
    }
    setListFormData(new FormData())
    handleUpload()
    setLoading(false)
  }
  useEffect(() => {
    getListEthnicity()
    getListProvinceVN()
    getProfileUser()
  }, [])

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: "100%" }}
      name="insertTop"
      scrollToFirstError={{
        behavior: "smooth",
        block: "center",
        inline: "center",
      }}
      getContainer={() => document.getElementById("body-scroll-layout")}
    >
      <Spin spinning={loading}>
        <PageIndexStyle>
          <div>
            <Affix
              offsetTop={isDraw ? 10 : heightForm ? 60 : 70}
              target={() => document.getElementById("body-scroll-layout")}
            >
              <div style={{ background: "#fff" }}>
                <div className="d-flex-sb">
                  <div className="d-flex">
                    {isDraw && (
                      <div
                        className="d-flex align-items-center mb-8"
                        style={{
                          cursor: "pointer",
                          padding: "4px 0",
                          color: "#154398",
                        }}
                        onClick={isDraw.onClose}
                      >
                        <LeftOutlined
                          style={{ color: "#ED1117", marginRight: 4 }}
                        />
                        <b>Trở về</b>
                      </div>
                    )}
                    <div className="anchor-wrap pl-0">
                      <Anchor
                        affix={false}
                        offsetTop={0}
                        getContainer={() =>
                          document.getElementById("form-scroll-layout")
                        }
                      >
                        <Link
                          href="#part-1"
                          title={
                            <div className="fs-16 fw-600 cl-menu">
                              Sơ yếu lý lịch
                            </div>
                          }
                        />
                        {/* <Link
                          href="#part-2"
                          title={
                            <div className="fs-16 fw-600 cl-menu">
                              Thông tin cá nhân
                            </div>
                          }
                        /> */}
                        <Link
                          href="#part-3"
                          title={
                            <div className="fs-16 fw-600 cl-menu">
                              Mối quan hệ
                            </div>
                          }
                        />
                        <Link
                          href="#part-4"
                          title={
                            <div className="fs-16 fw-600 cl-menu">
                              Quá trình học tập
                            </div>
                          }
                        />
                        <Link
                          href="#part-6"
                          title={
                            <div className="fs-16 fw-600 cl-menu">
                              Quá trình làm việc
                            </div>
                          }
                        />
                        <Link
                          href="#part-5"
                          title={
                            <div className="fs-16 fw-600 cl-menu">
                              Tài liệu hồ sơ
                            </div>
                          }
                        />
                      </Anchor>
                    </div>
                  </div>
                  <div>
                    {btnShow?.IsUpdate && (
                      <Button
                        btnType="primary"
                        form="myForm"
                        key="submit"
                        htmlType="submit"
                        onClick={() => handleSubmit()}
                      >
                        Lưu hồ sơ
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Affix>
          </div>
          <div
            className="scroll-form"
            style={heightForm ? { height: heightForm } : {}}
            id="form-scroll-layout"
          >
            <div id="part-1">
              <Curriculumvitae
                form={form}
                userInfo={userInfo}
                setAvatarUpload={setAvatarUpload}
                avatarUpload={avatarUpload}
              />
            </div>
            <div id="part-2">
              <Curriculumvitae2
                form={form}
                userInfo={userInfo}
                listProvince={listProvince}
              />
            </div>
            <div id="part-3">
              <Curriculumvitae3
                form={form}
                userInfo={userInfo}
                listRelationship={listRelationship}
                setListRelationship={setListRelationship}
                btnShow={btnShow}
              />
            </div>
            <div id="part-4">
              <Curriculumvitae4
                form={form}
                userInfo={userInfo}
                processStudys={processStudys}
                setProcessStudys={setProcessStudys}
                processOperation={processOperation}
                setProcessOperation={setProcessOperation}
                btnShow={btnShow}
              />
            </div>
            <div id="part-5">
              <Curriculumvitae5
                form={form}
                userInfo={userInfo}
                documentRequiment={documentRequiment}
                onOk={setDocumentRequiment}
                UserID={UserID}
                btnShow={btnShow}
                listFormData={listFormData}
              />
            </div>
          </div>
        </PageIndexStyle>
      </Spin>
    </Form>
  )
}

export default ProfilePage
