import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd"
import moment from "moment"
import { useEffect } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"

function ModalAddThanhVien(props) {
  const { isModalOpen, handleCancel, setListRelationship } = props
  const [form] = Form.useForm()

  useEffect(() => {
    if (!!isModalOpen) {
      form.setFieldsValue({
        ...isModalOpen,
        BirthYear: isModalOpen?.BirthYear
          ? moment(isModalOpen?.BirthYear, "YYYY")
          : undefined,
      })
    }
  }, [isModalOpen])
  const handleSubmit = async () => {
    try {
      const value = await form.validateFields()
      if (!!isModalOpen?.isEdit) {
        setListRelationship(pre => {
          pre[isModalOpen?.index] = {
            ...value,
            BirthYear: value?.BirthYear
              ? moment(value?.BirthYear).format("YYYY")
              : undefined,
          }
          return [...pre]
        })
      } else {
        setListRelationship(pre => [
          ...pre,
          {
            ...value,
            BirthYear: value?.BirthYear
              ? moment(value?.BirthYear?.format()).format("YYYY")
              : undefined,
          },
        ])
      }
      handleCancel()
      Notice({
        isSuccess: true,
        msg: "Cập nhật thành công!",
      })
    } finally {
    }
  }
  return (
    <CustomModal
      title={!!isModalOpen?.isEdit ? "Sửa Thành Viên" : "Thêm thành viên"}
      open={isModalOpen}
      // onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <Button
          style={{ float: "right" }}
          btnType="primary"
          onClick={() => {
            handleSubmit()
          }}
        >
          Ghi
        </Button>
      }
      width={"50%"}
    >
      <Form layout="vertical" form={form} initialValues={{ SortOrder: 0 }}>
        <Row gutter={16}>
          <Col span={18}>
            <Form.Item
              label="Họ tên"
              name="Name"
              rules={[
                {
                  required: true,
                  message: "Thông tin không được bỏ trống!",
                },
              ]}
            >
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Năm sinh" name="BirthYear">
              <DatePicker placeholder="Nhập" picker="year" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Điện thoại" name="PhoneNumber">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Nghề nghiệp" name="Career">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item
              label="Thứ tự"
              name="SortOrder"
              rules={[
                {
                  required: true,
                  message: "Thông tin không để trống!",
                },
              ]}
            >
              <InputNumber
                placeholder="nhập"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={value => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item label="Quan hệ với bản thân" name="Relationship">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Cơ quan công tác" name="Company">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Địa chỉ" name="Address">
              <Input placeholder="Nhập" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default ModalAddThanhVien
