import { Image, Tooltip } from "antd"
import { saveAs } from "file-saver"
import TableCustom from "src/components/Table/CustomTable"
import { BackGround, Title } from "../styled"
import { FloatActionWrapper } from "src/components/FloatAction/styles"
import ButtonCircle from "src/components/MyButton/ButtonCircle"
import ModalImportFile from "../Modal/ModalImportFile"
import { useState } from "react"
import { TYPE_LINK } from "src/constants/constants"
function Curriculumvitae5({
  documentRequiment,
  onOk,
  UserID,
  btnShow,
  listFormData,
}) {
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [visible, setVisible] = useState(false)
  const [listPreviewImg, setListPreviewImg] = useState(false)
  const columns = [
    {
      title: "STT",
      key: "stt",
      align: "center",
      width: 60,
      render: (value, record, index) => index + 1,
    },
    {
      title: "Tên giấy tờ",
      dataIndex: "Description",
      key: "Description",
      width: 200,
    },
    {
      title: "Tình trạng nộp",
      key: "Status",
      render: (value, record, index) =>
        !!record?.listFile.length ? "Đã nộp" : "Chưa nộp",
      align: "center",
      width: 140,
    },
    {
      title: "Tên file",
      dataIndex: "listFile",
      key: "listFile",
      render: (value, record, idx) => (
        <Tooltip
          title={
            <div>
              {!!value &&
                value?.map((item, idx) => (
                  <span
                    onClick={e => {
                      e?.stopPropagation()
                      saveAs(item?.FileUrl, item?.FileName)
                    }}
                    className="link-cus"
                    style={{ color: "#FFF" }}
                  >
                    {item?.FileName} {idx < value?.length && ", "}
                  </span>
                ))}
            </div>
          }
          mouseEnterDelay={0.8}
        >
          <div className="max-line2">
            {!!value &&
              value?.map((item, idx) => (
                <span
                  onClick={e => {
                    e?.stopPropagation()
                    saveAs(item?.FileUrl, item?.FileName)
                  }}
                  className="link-cus"
                  style={{ color: "#4096ff" }}
                >
                  {item?.FileName} {idx < value?.length - 1 && ", "}
                </span>
              ))}
            <FloatActionWrapper size="small" className="float-action__wrapper">
              {!!true && (
                <div className="d-flex">
                  {btnShow?.IsUpdate && (
                    <ButtonCircle
                      title="Tải file lên"
                      iconName="import"
                      style={{
                        background: "#EDF6FC",
                        boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                      }}
                      onClick={() => {
                        setOpenModalUpload(record)
                      }}
                    />
                  )}
                  {value?.filter(i => i?.TypeLink === TYPE_LINK.IMAGE_LINK)
                    ?.length > 0 && (
                    <ButtonCircle
                      title="Xem ảnh"
                      iconName="eye"
                      style={{
                        background: "#EDF6FC",
                        boxShadow: "0px 2px 4px rgba(208, 206, 187, 0.5)",
                      }}
                      onClick={() => {
                        setVisible(true)
                        setListPreviewImg(value)
                      }}
                    />
                  )}
                </div>
              )}
            </FloatActionWrapper>
          </div>
        </Tooltip>
      ),
    },
  ]

  return (
    <BackGround>
      <Title>TÀI LIỆU HỒ SƠ</Title>
      <TableCustom
        columns={columns}
        dataSource={documentRequiment}
        pagination={false}
        sticky={{ offsetHeader: 0 }}
        scroll={{ x: "700px" }}
      />
      {openModalUpload && (
        <ModalImportFile
          open={openModalUpload}
          onCancel={() => setOpenModalUpload(false)}
          onOk={onOk}
          UserID={UserID}
          listFormData={listFormData}
          documentRequiment={documentRequiment}
        />
      )}
      {visible && (
        <div
          style={{
            display: "none",
          }}
        >
          <Image.PreviewGroup
            preview={{
              visible,
              onVisibleChange: vis => setVisible(vis),
            }}
          >
            {listPreviewImg
              ?.filter(i => i?.TypeLink === TYPE_LINK.IMAGE_LINK)
              ?.map(i => (
                <Image src={i.FileUrl} key={i.FileUrl} />
              ))}
          </Image.PreviewGroup>
        </div>
      )}
    </BackGround>
  )
}

export default Curriculumvitae5
