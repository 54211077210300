import { Space, Spin, Upload } from "antd"
import { useEffect, useState } from "react"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import SvgIcon from "src/components/SvgIcon"

const ModalImportFile = ({
  open,
  onCancel,
  onOk,
  documentRequiment,
  listFormData,
}) => {
  const [loading, setLoading] = useState(false)
  const [listFile, setListFile] = useState([])
  const [listFileDelete, setListFileDelete] = useState([])
  const uploadFileProps = {
    name: "file",
    multiple: true,
    fileList: listFile,
    onChange(info) {
      setListFile(
        info.fileList.map(i => ({
          ...i,
          FileName: i.FileName ? i.FileName : i.name,
        })),
      )
    },
    onRemove(file) {
      if (file.ObjectFileID)
        setListFileDelete(pre => [...pre, file.ObjectFileID])
    },
    beforeUpload() {
      return false
    },
  }

  const handleUpload = () => {
    if (listFile?.length > 0 || listFileDelete.length > 0) {
      setLoading(true)
      listFile
        .filter(i => !!i.originFileObj)
        .map(i =>
          listFormData.append(
            `InsertFileList${open.CodeValue}`,
            i.originFileObj,
          ),
        )
      if (listFileDelete.length > 0)
        listFileDelete.map(i => listFormData.append(`DeleteFileList`, i))

      let newDocument = documentRequiment.map(i => {
        if (i.CodeValue === open.CodeValue) {
          return {
            ...open,
            listFile: listFile.map(it => ({
              ...it,
              TypeLink: 3,
            })),
          }
        }
        return i
      })
      onOk(newDocument)

      onCancel()
    } else {
    }
  }
  useEffect(() => {
    setListFileDelete([])
    setListFile([])
    if (open?.listFile?.length > 0)
      setListFile(
        open?.listFile?.map(i => ({
          ...i,
          name: i.FileName,
          url: i?.FileUrl,
        })),
      )
  }, [])
  return (
    <CustomModal
      title={`Tải file "${open?.Description}"`}
      open={open}
      onCancel={onCancel}
      footer={
        <Space size={16}>
          <Button btnType="primary" onClick={handleUpload}>
            Lưu lại
          </Button>
        </Space>
      }
    >
      <Spin spinning={loading}>
        <Upload.Dragger {...uploadFileProps}>
          <p className="d-flex justify-content-center">
            <SvgIcon name="cloud" />
            <span style={{ marginLeft: 8 }}>
              Kéo thả file đính kèm hoặc Click để
            </span>
            <span style={{ color: "#0747A6", marginLeft: "5px", fontSize: 15 }}>
              Chọn file
            </span>
          </p>
        </Upload.Dragger>
      </Spin>
    </CustomModal>
  )
}

export default ModalImportFile
