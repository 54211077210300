import { UserOutlined } from "@ant-design/icons"
import { Avatar, Col, DatePicker, Form, Input, Row, Select, Upload } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Button from "src/components/MyButton/Button"
import SelectAddress from "src/components/SelectAddress"
import SpinCustom from "src/components/Spin"
import SvgIcon from "src/components/SvgIcon"
import { SYSTEM_KEY } from "src/constants/constants"
import { getRegexEmail, getRegexMobile } from "src/lib/stringsUtils"
import { getBase64, getListComboByKey, normFile } from "src/lib/utils"
import FileService from "src/services/FileService"
import { BackGround, StyleMyAccount } from "../styled"
const Curriculumvitae = props => {
  const { form, userInfo, setAvatarUpload } = props
  const { listSystemKey } = useSelector(state => state.appGlobal)
  const [loading, setLoading] = useState(false)
  const [imgPreview, setImgPreview] = useState("")
  const { TextArea } = Input
  const formatDate = "DD/MM/YYYY"

  useEffect(() => {
    if (userInfo?.Avatar)
      form.setFieldsValue({
        Avatar: [
          {
            name: "a",
            url: userInfo?.Avatar,
            uid: "-1",
          },
        ],
      })
  }, [userInfo?.Avatar])

  const setImg = async file => {
    try {
      setLoading(true)
      const res = await getBase64(file)
      setImgPreview(res)
    } finally {
      setLoading(false)
    }
  }

  return (
    <SpinCustom spinning={loading}>
      <StyleMyAccount>
        <BackGround>
          <div gutter={[16, 16]} className="text-center">
            <div className="red">
              <b>Lời cam đoan: </b>
              <a className="red">
                Tôi xin cam đoan lời khai dưới đây là đúng sự thật nếu có điều
                gì khai man tôi xin chịu trách nghiệm hoàn toàn
              </a>
            </div>
          </div>
          <div className="text-center">
            <br></br>
            <b>CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM </b>
            <br></br>
            <b>Độc Lập-Tự Do-Hạnh Phúc</b>
            <br></br>
            <p>--------------</p>
            <h1>
              <b>SƠ YẾU LÝ LỊCH</b>
            </h1>
          </div>
          <div>
            <Row gutter={[16, 16]} className="d-flex">
              <Col span={6}>
                <div className="div-avatar">
                  <div>
                    <Avatar
                      src={imgPreview || userInfo?.Avatar}
                      size={150}
                      className="wrap-avatar"
                      icon={<UserOutlined style={{ fontSize: 70 }} />}
                    />
                  </div>
                  <Col className="sign-img" span={24}>
                    <Form.Item
                      // valuePropName="fileList"
                      getValueFromEvent={normFile}
                      name="Avatar"
                      // rules={[
                      //   () => ({
                      //     validator(_, value) {
                      //       if (value?.uid !== "-1") {
                      //         if (
                      //           !!value?.find(i => i?.size > 5 * 1024 * 1024)
                      //         ) {
                      //           return Promise.reject(
                      //             new Error("Dung lượng file tối đa 5MB"),
                      //           )
                      //         }
                      //       }
                      //       return Promise.resolve()
                      //     },
                      //   }),
                      // ]}
                    >
                      <Upload
                        beforeUpload={file => {
                          setAvatarUpload(file)
                          setImg(file)
                          return false
                        }}
                        accept="image/*"
                        multiple={false}
                        maxCount={1}
                        fileList={[]}
                      >
                        <div className="upload-avatar">
                          <div className="d-flex justify-content-center">
                            <Button className="sign-button-upload ">
                              <div className="sign-background-upload d-flex align-items-center">
                                <SvgIcon name="small-image-red" />
                                <div className="sign-text-upload ml-16">
                                  Chọn ảnh
                                </div>
                              </div>
                            </Button>
                            {/* {avatarUpload && (
                              <Button
                                btnType="primary"
                                className="ml-16"
                                style={{ width: 120 }}
                                onClick={e => {
                                  e.stopPropagation()
                                  changeAvatar()
                                }}
                              >
                                Lưu ảnh
                              </Button>
                            )} */}
                          </div>
                        </div>
                        <div
                          className="sign-text"
                          onClick={e => {
                            e.stopPropagation()
                          }}
                        >
                          <div>Dung lượng file tối đa 5MB</div>
                          <div>Định dạng:.JPG, .JPEG, .PNG, .SVG</div>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Col>
                </div>
              </Col>

              <Col span={18}>
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    <Form.Item
                      name="FullName"
                      label="Họ và Tên"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="Sex"
                      label="Giới tính"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Select placeholder="Chọn">
                        {getListComboByKey(
                          SYSTEM_KEY?.SEX_TYPE,
                          listSystemKey,
                        )?.map(i => (
                          <Select.Option
                            key={+i?.CodeValue}
                            value={+i?.CodeValue}
                          >
                            {i?.Description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <div>
                      <Form.Item
                        name="Birthday"
                        label="Ngày sinh"
                        rules={[
                          {
                            required: true,
                            message: "Thông tin không được để trống",
                          },
                        ]}
                      >
                        <DatePicker format={formatDate} />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="STK"
                      label="Số tài khoản ngân hàng"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="NganHang"
                      label="Ngân hàng"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="NguoiThuHuong"
                      label="Người thụ hưởng"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name={"Identification"}
                      label="Chứng minh nhân dân số"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập số cmnd" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="IdentificationDateRange"
                      label="Cấp ngày"
                      rules={[
                        {
                          required: true,
                          message: "Thông tin không được để trống",
                        },
                      ]}
                    >
                      <DatePicker format={formatDate} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="IdentificationIssuedBy"
                      label="Nơi cấp"
                      rules={[
                        {
                          required: true,
                          message: "Hãy chọn nơi cấp của bạn!",
                        },
                      ]}
                    >
                      <Input placeholder="Nhập" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row gutter={16} className="mt-16">
            <Col span={8}>
              <Form.Item
                name="PhoneNumber"
                label="Số điện thoại"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                  {
                    pattern: getRegexMobile(),
                    message: "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="TrinhDoHocVan" label="Trình độ" allowClear>
                {/* <Input placeholder="Nhập" /> */}
                <Select placeholder="Trình độ">
                  {getListComboByKey(SYSTEM_KEY?.DEGREE, listSystemKey)?.map(
                    i => (
                      <Select.Option key={+i?.CodeValue} value={+i?.CodeValue}>
                        {i?.Description}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="NgoaiNgu" label="Ngoại ngữ">
                <Select placeholder="Ngoại ngữ" allowClear>
                  {getListComboByKey(SYSTEM_KEY?.LANGUAGE, listSystemKey)?.map(
                    i => (
                      <Select.Option key={+i?.CodeValue} value={+i?.CodeValue}>
                        {i?.Description}
                      </Select.Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                  {
                    pattern: getRegexEmail(),
                    message: "Email nhập sai định dạng!",
                  },
                ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="Ethnicity" label="Dân tộc">
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="Religion" label="Tôn giáo">
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>

            {/* <Col span={24}>
              <h3 className="mt-12">
                <b>Nơi ở hiện nay</b>
              </h3>
            </Col> */}
            {/* <Col span={24}>
              <SelectAddress
                required={true}
                floating={false}
                form={form}
                listFormName={["ProvinceID", "DistrictID", "WardID"]}
                initValue={{
                  DistrictID: !!userInfo?.DistrictID
                    ? userInfo?.DistrictID
                    : undefined,
                  ProvinceID: !!userInfo?.ProvinceID
                    ? userInfo?.ProvinceID
                    : undefined,
                  WardID: !!userInfo?.WardID ? userInfo?.WardID : undefined,
                }}
              />
            </Col> */}
            <Col span={24}>
              <div>
                <Form.Item
                  name="Address"
                  // label="Số nhà/Đường phố/Thôn xóm"
                  label="Nơi ở hiện nay"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin không được để trống!",
                    },
                  ]}
                >
                  <Input placeholder="Nhập" />
                </Form.Item>
              </div>
            </Col>
            {/* <Col span={24}>
              <div className="pb-8 pt-16 fs-16">
                <b>Hộ khẩu Thường chú: </b>
              </div>
            </Col>
            <Col span={24}>
              <SelectAddress
                floating={false}
                form={form}
                listFormName={["ProvinceIDTT", "DistrictIDTT", "WardIDTT"]}
                initValue={{
                  DistrictIDTT: !!userInfo?.DistrictIDTT
                    ? userInfo?.DistrictIDTT
                    : undefined,
                  ProvinceIDTT: !!userInfo?.ProvinceIDTT
                    ? userInfo?.ProvinceIDTT
                    : undefined,
                  WardIDTT: !!userInfo?.WardIDTT
                    ? userInfo?.WardIDTT
                    : undefined,
                }}
              />
            </Col> */}
            <Col span={24}>
              <Form.Item
                name="AddressTT"
                label="Hộ khẩu thường chú:"
                // label="Số nhà/Đường phố/Thôn xóm"
                // rules={[
                //   {
                //     required: true,
                //     message: "Thông tin không được để trống",
                //   },
                // ]}
              >
                <Input placeholder="Nhập" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div>
                <Form.Item
                  name="AddressNQ"
                  label="Nguyên quán"
                  // label="Số nhà/Đường phố/Thôn xóm"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Hãy chọn Số nhà/Đường phố/Thôn xóm của bạn!",
                  //   },
                  // ]}
                >
                  <Input placeholder="Nhập" />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="KhiCanThiBaoAi"
                label="Khi cần báo tin cho ai/ở đâu/Điện thoại?"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống",
                  },
                ]}
              >
                <TextArea
                  placeholder="Thông tin liên hệ"
                  style={{
                    height: "128px",
                    overflow: "hidden auto",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </BackGround>
      </StyleMyAccount>
    </SpinCustom>
  )
}

export default Curriculumvitae
