import React from 'react';
import ProfilePage from 'src/pages/ADMIN/Curriculumvitae';
import LayoutCommon from "src/components/Common/Layout"
const Resume = () => {
    return (
        <LayoutCommon>
            <ProfilePage heightForm={"100vh"} />
        </LayoutCommon>
    );
}

export default Resume;
